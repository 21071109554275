:root {
    --dark-gray: #151515;
    --light-gray: #F2F2F2;
    --almost-dark-gray: #5c5c5c;
    --white: #FFFFFF;
    --black: #000000;
    --gray: #CCCCCC;
    --red: #FF0000;
    --green: #00FF00;
    --blue: #0000FF;
    --yellow: #FFFF00;
}


/*! Keyboard */
.keyboard-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    width: 80%;
    height: 300px;
    margin: auto 
}
  
.key-row {
    display: flex;
    justify-content: center;
}


/*! Active and Passive Keyboard Key */
.lActiveKey, .dPassiveKey, .lPassiveKey:hover {
    background-color: var(--dark-gray);
    color: var(--white);
}

.dActiveKey, .dPassiveKey:hover {
    background-color: var(--light-gray);
    color: var(--dark-gray);
}

.lPassiveKey {
    background-color: var(--white);
    color: var(--dark-gray);
}

.lActiveKey, .lPassiveKey {
    border: 1px solid var(--dark-gray);
}

.dActiveKey, .dPassiveKey {
    border: 1px solid var(--light-gray);
}

.lActiveKey:hover, .dActiveKey:hover { 
    background-color: var(--almost-dark-gray);
    color: var(--white);
    border: 1px solid var(--light-gray);
}

/*? Responsiveness */
@media screen and (max-width: 1250px) {
    .lActiveKey, .lPassiveKey, .dActiveKey, .dPassiveKey {
        width: calc((44vw - 20px) / 7);
    }
}

@media screen and (max-width: 810px) {
    .lActiveKey, .lPassiveKey, .dActiveKey, .dPassiveKey {
        font-size: 14px;
        margin: 3px 0px;
        width: calc((80vw - 12px) / 7);
    }
}


/*! Bottom Buttons */
.lBottom, .dBottom {
    width: 100%;
    border-radius: 0px 0px 30px 30px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    padding-top: 10px;
}

.lBottom:hover, .dBottom:hover {
    background-color: var(--almost-dark-gray);
    color: var(--white);
}


/*! Other Active Elements */
.lActive, .lBottom {
    background-color: var(--dark-gray);
    color: var(--white)
}

.dActive, .dBottom {
    background-color: var(--light-gray);
    color: var(--dark-gray)
}

.lActive:hover, .dActive:hover {
    background-color: var(--almost-dark-gray);
    color: var(--white);
    border: 1px solid var(--light-gray);
}


/*! Passive Pagination Keys */
.dPassiveKeyPagination {
    color: var(--light-gray);
    background-color: var(--dark-gray);
    border: 1px solid var(--dark-gray);
}

.lPassiveKeyPagination {
    color: var(--dark-gray);
    background-color: var(--light-gray);
    border: 1px solid var(--light-gray);
}

.lPassiveKeyPagination:hover {
    background-color: var(--dark-gray);
    color: var(--white);
}

.dPassiveKeyPagination:hover {
    background-color: var(--light-gray);
    color: var(--dark-gray);
}


/*! Colored Buttons */
.success {
	border: 1px solid #28a745;
	color: #28a745;
}

.success:hover {
	background-color: #28a745;
	color: white;
}

.danger {
	border: 1px solid #dc3545;
	color: #dc3545;
}

.danger:hover {
	background-color: #dc3545;
	color: white;
}

.warning {
	border: 1px solid #eeb118;
	color: #eeb118;
}

.warning:hover {
	background-color: #eeb118;
	color: black;
}

.linkedin {
	border: 1px solid #0A66C2;
	color: #0A66C2;
}

.linkedin:hover {
	background-color: #0A66C2;
	color: white;
}

.whatsapp {
	border: 1px solid #2CB742;
	color: #2CB742;
}

.whatsapp:hover {
	background-color: #2CB742;
	color: white;
}
